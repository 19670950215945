import { useId } from 'react';
import { Box, Typography, Stack, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import { InfoTooltip } from 'component/new_design/base/InfoTooltip';
import { TextField } from 'component/new_design/base/forms/TextField';
import { SelectField } from 'component/new_design/base/forms/SelectField';
import { SwitchField } from 'component/new_design/base/forms/SwitchField';

export interface WordpressInfoFormValue {
  username: string;
  password: string;
  email: string;
  multisite: boolean;
  preferredLanguage: string;
  atarim: boolean;
  wooCommerce: boolean;
  wpRecommendedPlugins: boolean;
}

interface Props {
  readonly onComplete: (data: WordpressInfoFormValue) => void;
  readonly formId: string;
  readonly hasTemplateId: boolean;
}

export const WordpressInformationStep = ({ onComplete, formId, hasTemplateId }: Props) => {
  const { t } = useTranslation();

  const methods = useForm<WordpressInfoFormValue>({
    defaultValues: {
      username: '',
      password: '',
      email: '',
      preferredLanguage: 'english',
      atarim: false,
      multisite: false,
      wooCommerce: false,
      wpRecommendedPlugins: hasTemplateId,
    },
    mode: 'onChange',
  });

  const multisiteLabelId = useId();
  const wooCommerceLabelId = useId();
  const atarimLabelId = useId();
  const recommendedPluginsLabelId = useId();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onComplete)} id={formId}>
        <Box
          sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(auto, 320px))', gap: 4 }}
        >
          <TextField
            name="username"
            label={t('wordpress_username')}
            placeholder={t('wordpress_username_placeholder')}
            fullWidth
            rules={{
              required: true,
            }}
          />
          <TextField
            name="password"
            label={t('wordpress_password')}
            fullWidth
            type="password"
            rules={{
              required: true,
            }}
          />
          <TextField
            name="email"
            label={t('wordpress_email')}
            fullWidth
            rules={{
              validate: email => isEmail(email) || t('invalid_email'),
            }}
          />
          <SelectField
            name="preferredLanguage"
            label={t('language')}
            fullWidth
            options={[
              {
                value: 'english',
                label: t('english'),
              },
            ]}
          />
        </Box>
        <Box mt={4}>
          <Typography variant="body2">
            {t('additional_options')}
            {hasTemplateId ? (
              <InfoTooltip>{t('create_site_using_a_template_no_options_warning')}</InfoTooltip>
            ) : null}
          </Typography>
          <Typography variant="body2" color="greys.500">
            {t('additional_options_details')}
          </Typography>
        </Box>
        <Stack divider={<Divider flexItem />} gap={3} mt={4}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" id={multisiteLabelId}>
              {t('multisite_support')}
            </Typography>
            <SwitchField
              disabled={hasTemplateId}
              name="multisite"
              aria-labelledby={multisiteLabelId}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" id={wooCommerceLabelId}>
              {t('woocommerce')}
            </Typography>
            <SwitchField
              disabled={hasTemplateId}
              name="wooCommerce"
              aria-labelledby={wooCommerceLabelId}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" id={atarimLabelId}>
              {t('atarim_collaborate')}
            </Typography>
            <SwitchField disabled={hasTemplateId} name="atarim" aria-labelledby={atarimLabelId} />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" id={recommendedPluginsLabelId}>
              {t('wp_recommended_plugins')}
              {!hasTemplateId ? (
                <InfoTooltip>
                  <Typography variant="caption">
                    {t('wp_recommended_plugins_help')}
                    <ul style={{ listStyle: 'inside' }}>
                      <li>{t('wp_recommended_plugins_help_item_1')}</li>
                      <li>{t('wp_recommended_plugins_help_item_2')}</li>
                      <li>{t('wp_recommended_plugins_help_item_3')}</li>
                      <li>{t('wp_recommended_plugins_help_item_4')}</li>
                      <li>{t('wp_recommended_plugins_help_item_5')}</li>
                    </ul>
                  </Typography>
                </InfoTooltip>
              ) : null}
            </Typography>
            <SwitchField
              disabled={hasTemplateId}
              name="wpRecommendedPlugins"
              aria-labelledby={recommendedPluginsLabelId}
            />
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};
